import { Fragment } from "react/jsx-runtime";
import { CenteredSpinner } from "../../../../components/Spinner";
import DropIndicator from "./DropIndicator";
import Question from "./Question";

export default function QuestionSet({
  sectionNumber,
  questions,
  startNumber,
  onDelete,
  onAdd,
  onQuestionChange,
  onNext,
  dropIndex,
  setDropIndex,
  onDrop,
  setDragId,
  dragId,
  requestedCursorPosition,
  registerCursorUpdate,
  onQuestionLengthChange,
}: {
  sectionNumber: number;
  dropIndex: number | null;
  setDropIndex: (index: number | null) => void;
  questions: any[];
  startNumber: number;
  onDelete: (id: string) => void;
  onAdd: (i: number, content?: any) => void;
  onQuestionChange: (
    id: string,
    text: string,
    options?: {
      accepted?: boolean;
      previousText?: string;
    }
  ) => void;
  onNext?: () => void;
  onDrop: () => void;
  dragId: string | null;
  setDragId: (id: string | null) => void;
  requestedCursorPosition: { id: string; pos: number } | null;
  registerCursorUpdate: () => void;
  onQuestionLengthChange: (questionId: string, length: number) => void;
  moveCursorTo: (id: string, pos?: number) => void;
}) {
  const canClickNext = questions.every((q) => q.accepted);
  const dragIndex = questions.findIndex((q) => q.id === dragId);

  return (
    <div>
      <div className="py-1">
        <DropIndicator
          key={`${sectionNumber}-0`}
          ariaLabel={`Drop indicator for section ${sectionNumber}, position 0`}
          show={dropIndex === 0 && dragIndex !== 0}
          onDragOver={() => setDropIndex(0)}
          onDragLeave={() => setDropIndex(null)}
        />
        {questions.map((question, q) => (
          <Fragment key={question.id}>
            <Question
              i={q}
              key={question.id}
              id={question.id}
              accepted={question.accepted}
              number={startNumber + q}
              onChange={(text, options) =>
                onQuestionChange(question.id, text, options)
              }
              text={question.text}
              previousText={question.previousText}
              onDelete={() => onDelete(question.id)}
              onAdd={(i) => onAdd(i, "")}
              onDrop={() => {
                onDrop();
              }}
              setDragId={setDragId}
              disabled={dragId !== null}
              registerCursorUpdate={registerCursorUpdate}
              requestedCursorPosition={
                requestedCursorPosition !== null &&
                requestedCursorPosition.id === question.id
                  ? requestedCursorPosition.pos
                  : null
              }
              onLengthChange={(length) =>
                onQuestionLengthChange(question.id, length)
              }
            />
            <DropIndicator
              key={`${sectionNumber}-${q + 1}`}
              ariaLabel={`Drop indicator for section ${sectionNumber}, position ${
                q + 1
              }`}
              show={
                dropIndex === q + 1 && dragIndex !== q && dragIndex !== q + 1
              }
              onDragOver={() => setDropIndex(q + 1)}
              onDragLeave={() => setDropIndex(null)}
            />
          </Fragment>
        ))}
      </div>
      {onNext ? (
        <div className="flex flex-row justify-end">
          {!canClickNext && (
            <div className="text-sm text-gray-500 flex flex-col justify-center mr-4 italic">
              Respond to all suggestions to generate the next section.
            </div>
          )}
          <button
            disabled={!canClickNext}
            onClick={onNext}
            className="inline-flex items-center rounded-md bg-sky-800 disabled:bg-gray-300 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-700"
          >
            Next
          </button>
        </div>
      ) : null}
    </div>
  );
}
