import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
  addNormalizedItems,
  initNormalizedState,
  NormalizedState,
  toArray,
} from "../../utils/normalizedState";
import { fetchAll } from "./completionSessionAPI";

const initialState: NormalizedState<any> = initNormalizedState();

export const getAllCompletionSessions = createAsyncThunk(
  "completionSessions/getAll",
  async (surveyId: string) => {
    const response = await fetchAll({ surveyId });
    return response;
  }
);

export const completionSessionSlice = createSlice({
  name: "completionSessions",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder.addCase(
      getAllCompletionSessions.fulfilled,
      (state: any, action: PayloadAction<any>) => {
        addNormalizedItems(state, action.payload.results);
      }
    );
  },
});

export const selectManyCompletionSessionsBySurveyId = createSelector(
  [
    (state: RootState) => state.completionSessions,
    (state: RootState, surveyId: string) => surveyId,
  ],
  (completionSessions: any, surveyId: string) => {
    return toArray(completionSessions).filter(
      (cs: any) => cs.surveyId === surveyId
    );
  }
);

export const selectCompletionSessionById = createSelector(
  [
    (state: RootState) => state.completionSessions.byId,
    (state: RootState, completionSessionId: string) => completionSessionId,
  ],
  (surveysById: any, id: string) => {
    return surveysById[id] || null;
  }
);

export default completionSessionSlice.reducer;
