import {
  Editor,
  EditorContent,
  Extension,
  generateHTML,
  useEditor,
} from "@tiptap/react";
import Placeholder from "@tiptap/extension-placeholder";
import StarterKit from "@tiptap/starter-kit";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import config from "../../../../config";
import { EditorView } from "@tiptap/pm/view";
import invariant from "tiny-invariant";

export default function EditableDiv({
  id,
  value,
  placeholder,
  onChange,
  className,
  onEnter,
  autoFocus = false,
  onBackspaceAtStart,
  ariaLabel,
  disabled = false,
  registerCursorUpdate,
  requestedCursorPosition = null,
  onLengthChange,
  onFocus,
  onBlur,
}: {
  id?: string;
  value: string;
  placeholder?: string;
  className?: string;
  onEnter?: (before?: string, after?: string) => void;
  onChange: (content: string) => void;
  autoFocus?: boolean;
  onBackspaceAtStart?: (content: string) => void;
  ariaLabel?: string;
  disabled?: boolean;
  registerCursorUpdate?: () => void;
  requestedCursorPosition?: number | null;
  onLengthChange?: (length: number) => void;
  onFocus?: () => void;
  onBlur?: () => void;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const onEnterRef = useRef(onEnter);
  onEnterRef.current = onEnter;
  const onBackspaceAtStartRef = useRef(onBackspaceAtStart);
  onBackspaceAtStartRef.current = onBackspaceAtStart;
  let editor: null | Editor = null;
  const [cursorAttempts, setCursorAttempts] = useState(0);
  useEffect(() => {
    if (requestedCursorPosition === null) return;
    if (!ref.current || !editor) {
      setCursorAttempts((attempts) => attempts + 1);
      if (cursorAttempts > 5) {
        console.error("Failed to set cursor after 5 attempts");
        setCursorAttempts(0);
        if (registerCursorUpdate) registerCursorUpdate();
      }
      return;
    }
    editor.chain().focus().setTextSelection(requestedCursorPosition).run();
    ref.current.scrollIntoView({ block: "center" });
    if (registerCursorUpdate) registerCursorUpdate();
    setCursorAttempts(0);
  }, [requestedCursorPosition, cursorAttempts]);

  useEffect(() => {
    if (onLengthChange) onLengthChange(value.length);
  }, [value]);

  const extensions = [
    StarterKit,
    Placeholder.configure({
      placeholder,
    }),
  ];
  const DisableEnter = Extension.create({
    addKeyboardShortcuts() {
      return {
        Enter: ({ editor }) => {
          invariant(onEnterRef.current);
          const range = editor.state.selection.ranges[0];
          if (!range) {
            onEnterRef.current();
            return true;
          }
          const { $from, $to } = range;
          const from = $from.pos;
          const to = $to.pos;
          const beforeJSON = editor.state.doc.slice(0, from).toJSON();
          const afterJSON = editor.state.doc
            .slice(to, editor.state.doc.nodeSize - 2)
            .toJSON();
          const beforeHTML = generateHTML(
            {
              type: "doc",
              content: beforeJSON.content,
            },
            extensions
          );
          const afterHTML = generateHTML(
            {
              type: "doc",
              content: afterJSON.content,
            },
            extensions
          );
          if (to) {
            const tr = editor.state.tr.delete(
              to,
              editor.state.doc.nodeSize - 2
            );
            editor.view.dispatch(tr);
          }

          onEnterRef.current(beforeHTML, afterHTML);

          return true;
        },
      };
    },
  });

  if (onEnter) {
    extensions.push(DisableEnter);
  }
  const editorProps = {
    editable: () => !disabled,
    attributes: {
      class: className || "",
      ...(id && { id: id }),
      ...(ariaLabel && { "aria-label": ariaLabel }),
    },

    handleDOMEvents: {
      keydown(view: EditorView, event: KeyboardEvent) {
        if (
          event.key === "Backspace" &&
          view.state.selection.$from.pos === 1 &&
          view.state.selection.$to.pos === 1
        ) {
          if (onBackspaceAtStartRef.current) {
            onBackspaceAtStartRef.current(editor?.getHTML() || "");
          }
        }
      },
    },
  };
  editor = useEditor({
    content: value,
    extensions,
    autofocus: autoFocus,
    editorProps,
    onUpdate: handleUpdate,
    onFocus: () => {
      if (onFocus) onFocus();
    },
    onBlur: () => {
      if (onBlur) onBlur();
    },
  });

  useEffect(() => {
    if (editor && editor.getHTML() !== value) {
      editor.commands.setContent(value);
    }
  }, [value]);
  useEffect(() => {
    if (editor) {
      editor.setOptions({
        editorProps,
      });
    }
  }, [editor, className, disabled]);

  function handleUpdate() {
    if (!editor) {
      return;
    }
    const html = editor.getHTML();
    const isEmpty = html === "<p></p>";
    onChange(isEmpty ? "" : html);
  }
  if (!editor) {
    return null;
  }
  return (
    <EditorContent
      ref={ref}
      editor={editor}
      className={`${!config.posthog.recordInputValues ? "ph-no-capture" : ""}`}
    ></EditorContent>
  );
}
