import { Link } from "react-router-dom";
import config from "../config";
import { useAppSelector } from "../app/hooks";

export default function NotFound() {
  const user = useAppSelector((state) => state.auth.user);
  return (
    <div className="text-center mt-48 flex-1">
      <div className="font-bold mb-2" style={{ fontSize: 24 }}>
        Not Found
      </div>
      <div className="text-gray-600">
        Sorry, this page does not exist. Please check the URL and try again.
      </div>
      {!config.production && !user ? (
        <div className="text-gray-600 text-sm mt-8">
          Not logged in? Log in{" "}
          <Link
            to="/drafter/login"
            className="text-sm font-bold text-gray-700 hover:underline"
          >
            here
          </Link>
          .
        </div>
      ) : null}
    </div>
  );
}
