import { useEffect, useState } from "react";

export function useDrag({
  onDrop,
}: {
  onDrop: (dragId: string, dropTargetId: string, index: number) => void;
}) {
  const [dragId, setDragId] = useState<string | null>(null);
  const [dropTarget, setDropTarget] = useState<{
    id: string;
    index: number;
  } | null>(null);

  const [dropped, setDropped] = useState(false);
  useEffect(() => {
    if (dropped) {
      if (dragId && dropTarget) {
        onDrop(dragId, dropTarget.id, dropTarget.index);
      }
      setDragId(null);
      setDropped(false);
      setDropTarget(null);
    }
  }, [dropped]);

  return {
    dragId,
    setDragId,

    isDragging: dragId !== null,
    dropTarget,
    setDropTarget,
    setDropped,
  };
}
