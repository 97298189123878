import { Fragment, useState } from "react";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import SubmitButton from "../../../../../components/form/SubmitButton";
import { handleError } from "../../../../errors/errorUtils";
import { submit as submitSurvey } from "../../../surveyAPI";
import { useNavigate } from "react-router-dom";

export default function ReadyForProgrammingDialog({
  open,
  setOpen,
  surveyId,
  setSurvey,
  onSubmit,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  surveyId: string;
  setSurvey: (survey: any) => void;
  onSubmit?: () => void;
}) {
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    e.stopPropagation();
    try {
      setSubmitting(true);
      const { submittedAt } = await submitSurvey(surveyId);
      setSurvey((survey: any) => ({
        ...survey,
        submittedAt,
      }));
      if (onSubmit) onSubmit();
      setOpen(false);
      navigate(`/drafter/surveys/${surveyId}/submitted`);
    } catch (err) {
      handleError(err);
    } finally {
      setSubmitting(false);
    }
  }
  return (
    <Transition show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <form onSubmit={handleSubmit}>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <CheckIcon
                      className="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 sm:mt-5">
                    <DialogTitle
                      as="h3"
                      className="text-center text-base font-semibold leading-6 text-gray-900 mb-4"
                    >
                      Great! Let's get you ready to launch.
                    </DialogTitle>
                    <div className="mt-2 space-y-6 text-sm text-gray-500 leading-5">
                      By clicking "Submit", you will be sending your survey to a
                      IncQuery Survey Director to begin the programming process.
                      They will review and load your survey into the IncQuery
                      authoring tool, where you will have a chance to review and
                      make changes before the survey is published.
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <SubmitButton
                      submitting={submitting}
                      className="inline-flex w-full justify-center rounded-md bg-sky-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                    >
                      Submit
                    </SubmitButton>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                      data-autofocus
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
