import { dropTargetForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { useEffect, useRef } from "react";
import invariant from "tiny-invariant";

export default function DropIndicator({
  ariaLabel,
  show,
  onDragOver,
  onDragLeave,
}: {
  ariaLabel?: string;
  show: boolean;
  onDragOver: () => void;
  onDragLeave: () => void;
}) {
  const ref = useRef(null);
  useEffect(() => {
    const el = ref.current;
    invariant(el);
    return dropTargetForElements({
      element: el,
      onDrag() {
        onDragOver();
      },
      onDragLeave() {
        onDragLeave();
      },
    });
  }, []);
  return (
    <div aria-label={ariaLabel} className="h-6" ref={ref}>
      <div
        className={`${
          show ? "" : "invisible"
        } flex flex-row items-center h-full`}
      >
        <div className="w-2 h-2 rounded-full border-2 border-sky-600"></div>
        <div
          className={`flex-1 bg-sky-600 relative`}
          style={{ height: 2 }}
        ></div>
      </div>
    </div>
  );
}
