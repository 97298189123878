import { useNavigate, useParams } from "react-router-dom";
import { CenteredSpinner } from "../components/Spinner";
import { getSurvey, selectSurveyById } from "../features/survey/surveySlice";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { useEffect, useState } from "react";
import { throwIfError } from "../utils/error";
import SurveyWorkspace from "../features/survey/editor/SurveyWorkspace";

export default function SurveyWorkspaceView() {
  const { surveyId } = useParams();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  if (!surveyId) throw new Error("Survey id is required.");

  const survey = useAppSelector((state) => selectSurveyById(state, surveyId));
  useEffect(() => {
    loadSurvey();
  }, []);
  useEffect(() => {
    if (!survey) {
      document.title = "Survey | IncQuery";
    } else {
      document.title = `${survey?.title || "Untitled"} | IncQuery`;
    }
  }, [survey?.title]);

  async function loadSurvey() {
    if (!surveyId) return;
    try {
      await throwIfError(dispatch(getSurvey(surveyId)));
    } catch (err) {
      console.error(err);
      navigate("/404", { replace: true });
    } finally {
      setLoading(false);
    }
  }

  if ((!survey || !survey.sections) && loading)
    return (
      <div className="h-56 flex flex-col justify-center items-center">
        <CenteredSpinner />
      </div>
    );

  if (!survey) return null;

  return (
    <div className="max-h-screen">
      <SurveyWorkspace survey={survey} />
    </div>
  );
}
