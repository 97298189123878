const numberedLineRegex = /^\d+\.\s/;

export function getSectionStartingNumbers(counts: number[]) {
  return counts.reduce((acc, val: number) => {
    if (acc.length === 0) return [1, val + 1];
    acc.push(acc[acc.length - 1] + val);
    return acc;
  }, [] as number[]);
}

export function parseSchematicCompletion(text: string) {
  const schematic = {
    title: "",
    objective: "",
    audience: "",
    sections: [] as { title: string; objectives: string }[],
  };
  const lines = text
    .split("\n")
    .map((line) => line.trim())
    .filter((line) => line.length > 0);
  let currentField = null as
    | "title"
    | "objective"
    | "audience"
    | "sections"
    | null;
  lines.forEach((line) => {
    switch (line) {
      case "TITLE":
        currentField = "title";
        return;
      case "OBJECTIVE":
        currentField = "objective";
        return;
      case "AUDIENCE":
        currentField = "audience";
        return;
      case "SECTIONS":
        currentField = "sections";
        return;
    }
    if (!currentField) return;
    if (currentField === "title") {
      if (schematic[currentField].length > 0) return;
      schematic[currentField] = line;
    } else if (currentField === "sections") {
      if (line.match(numberedLineRegex)) {
        const lineText = line.replace(numberedLineRegex, "");
        schematic.sections.push({
          title: lineText,
          objectives: "",
        });
      } else {
        const lastSection = schematic.sections[schematic.sections.length - 1];
        if (!lastSection) return;
        if (lastSection.objectives.length > 0) lastSection.objectives += "\n";
        lastSection.objectives += line;
      }
    } else {
      if (schematic[currentField]) schematic[currentField] += "\n";
      schematic[currentField] += line;
    }
  });
  return schematic;
}

export const exampleScopeQuestions = `1. Market Overview and Segmentation
Analyze global and regional smartphone market sizes and segmentation. Explore market growth trends, purchase dynamics, and value drivers. Identify TechAdvance Co.’s opportunities and optimal focus areas.

2. Premium Market Analysis
Determine the size and segments of the global smartphone services market, with a focus on potential competitive segments for TechAdvance Co. Evaluate market growth, pricing, and volume trends, and pinpoint where TechAdvance Co. can gain market share.

3. Competitive Landscape
Identify key competitors in the smartphone and services market, assessing their market size, growth, and segment focus. Explore customer satisfaction, competitor differentiation, and TechAdvance Co.’s market position, threats, and opportunities.

4. Customer Perceptions
Investigate customer purchase criteria, segmentation, satisfaction with TechAdvance Co. compared to competitors, brand awareness, and perception differences across telco and consumer electronics sectors.

5. Value Creation & Expansion
Identify improvement and growth opportunities within TechAdvance Co.’s existing services and markets. Explore pricing, cost efficiency, and adjacencies for expansion, including recommerce and emerging market strategies.`;

export const audienceExample = `•	Employed full-time
•	Decision-maker for internet, video conferencing and cloud/VoIP phone and /or text/chat for the organization
•	Telecom services are currently used in organization
•	Involved in purchase of telecom services for the organization in the past two years`;

export const existingExampleSurvey = `<h2>Screening questions</h2>

1.	What is your gender?
a)	Male
b)	Female
c)	Other
d)	Prefer not to say

2.	What is your age?
a)	Under 18 [TERMINATE]
b)	18-24
c)	25-34
d)	35-44
e)	45-54
f)	55-64
g)	65+

3.	What was your annual household income last year (before taxes)?
a)	Less than $25,000
b)	$25,000 - $49,999
c)	$50,000 to $74,999
d)	$75,000 to $99,999
e)	$100,000 to $199,999
f)	$200,000 or more

4.	Which of the following best describes your ethnicity? (check all that apply)
a)	White or Caucasian
b)	Black or African American
c)	Asian or Asian American
d)	American Indian, Alaska Native, Native Hawaiian or Pacific Islander
e)	Other [text input]
f)	Prefer not to say

5.	 Do you consider yourself to be of Hispanic or Latino origin? 
a)	Yes
b)	No
6.	What is the zip code in which you reside?
Require valid US zip code
Min length: 5
Max length: 5

7.	Which of the following have you done in the past 12 months?  Please select all that apply
a)	Purchased a vehicle [QUALIFY]
b)	Shopped for groceries [QUALIFY]
c)	Rode in a hot air balloon [TAG: FLAG] [QUALIFY]
d)	Stayed in a hotel overnight [QUALIFY]
e)	Received pilot's license [TAG: FLAG] [QUALIFY]
f)	Flown on an airplane [QUALIFY]
g)	Adopted a pet [QUALIFY]
h)	None of the above

8.	Which of the following Grocery Stores/Supermarkets are you aware of?  Please select all that apply
a)	Aldi [QUALIFY]
b)	Albertsons [QUALIFY]
c)	Costco [QUALIFY]
d)	Eiseltmans [TAG: FAKE] [QUALIFY]
e)	HEB [QUALIFY]
f)	Kroger [QUALIFY]
g)	Publix [QUALIFY]
h)	Safeway [QUALIFY]
i)	Target [QUALIFY]
j)	Tom Thumb [QUALIFY]
k)	Trader Joes [QUALIFY]
l)	Walmart [QUALIFY]
m)	Whole Foods [QUALIFY]
n)	Other (Specify) [text input] [QUALIFY]
o)	None of the above

9.	How would you best describe your familiarity with the following Grocery Stores/Supermarkets?
Aware but never shopped
Shopped over 12 months ago
Shopped within the past 7-11 months
Shopped within the past 4-6 months
Shopped within the past 3 months		

<h2>Main</h2>
10.	Of your total grocery shopping trips in the past 3 months, what percentage of your visits were at each store (Your best estimate is fine)
Dynamic choices: l3m_brands

11.	Please rank the following grocery stores, where 1 is your favorite/preferred store (select up to 3)
Dynamic choices: l3m_brands

Set nps_brands to Select up to 3 'l3m_brands' (randomized selection)
[Repeat the following question for each: nps_brands]
12.	On a scale of 0-10, where 0=Not at all Likely and 10=Extremely likely, how likely would you be to recommend brand to a friend or family member?
a)	0 = Not at all Likely
b)	1
c)	2
d)	3
e)	4
f)	5
g)	6
h)	7
i)	8
j)	9
k)	10 = Extremely Likely 

13.	For the stores you've shopped at in the past 3 months, how did you make your purchase?
In-Store	Online/Delivery
l3m_brands		

14.	Which of the following grocery categories did you purchase on your most recent visit?
a)	Deli meat
b)	Frozen dinner
c)	Soft drinks
d)	Fruits/vegetables
e)	Bread
f)	Dairy
g)	Chips/crackers
h)	Coffee
i)	Personal care/Toiletries
j)	None of the above [TAG: FLAG]
Set KPCs to:
a)	Location
b)	Cost of goods
c)	Availability of specific goods or brands
d)	Quality of service
e)	Hours of operation
f)	Other [text input]

15.	Which of the following criteria are most important to you when choosing a grocery store/supermarket? (Please select up to 3 criteria)
Dynamic choices: KPCs
[Repeat the following question for each: nps_brands]

16.	How would you rate brand's performance across these criteria?


	1 - Does not perform well at all	2	3	4	5 - Performs extremely well
Q15 displayed choices with write-ins for ‘other’					
					
					
<h2>Demographics</h2>

17.	How many children under the age of 18 live in the household?
a)	0
b)	1
c)	2
d)	3
e)	4+

18.	What is the highest level of education you have completed?
a)	Less than high school
b)	High school graduate
c)	Some college or technical school
d)	Bachelors Degree
e)	Graduate Degree
f)	Doctorate/Ph.D.
`;

export function randomNoun() {
  const nouns = [
    "Acrobat",
    "Bluebird",
    "Cascade",
    "Driftwood",
    "Echo",
    "Firefly",
    "Gossamer",
    "Harmony",
    "Indigo",
    "Jasmine",
    "Kaleidoscope",
    "Lullaby",
    "Mirage",
    "Nocturne",
    "Oasis",
    "Prism",
    "Quill",
    "Rhapsody",
    "Silhouette",
    "Trinket",
    "Ultraviolet",
    "Velvet",
    "Whisper",
    "Xanadu",
    "Zephyr",
  ];
  return nouns[Math.floor(Math.random() * nouns.length)];
}
