export default function ErrorFallback() {
  return (
    <div className="text-center mt-48">
      <div className="font-bold mb-2" style={{ fontSize: 24 }}>
        Sorry!
      </div>
      <div className="text-gray-600">
        An unexpected error has occurred and has been reported. We'll be looking
        into it shortly.
      </div>
    </div>
  );
}
