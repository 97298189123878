import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useSurveySetters } from "./hooks/useSurvey";
import { trackEvent } from "../../../utils/tracking";
import Section from "./components/Section";
import { useRecord } from "./hooks/useRecord";
import QuestionSet from "./components/QuestionSet";
import { SurveyData } from "../survey.types";
import { useDrag } from "./hooks/useDrag";
import useCursor from "./hooks/useCursor";
import { CenteredSpinner } from "../../../components/Spinner";

export default function SurveyEditor({
  survey,
  setSurvey,
  generating = false,
  collapse = false,
}: {
  survey: SurveyData;
  setSurvey: Dispatch<SetStateAction<SurveyData>>;
  generating: boolean;
  collapse?: boolean;
}) {
  const {
    addSection,
    setSection,
    removeSection,
    addQuestion,
    removeQuestion,
    moveQuestion,
    sectionStartingNumbers,
    setQuestion,
  } = useSurveySetters(survey, setSurvey);
  const {
    registerCursorUpdate,
    requestedCursorPosition,
    moveCursorTo,
    registerInputLength,
  } = useCursor();
  const sectionRef = useRef<HTMLDivElement>(null);
  const { dropTarget, setDropTarget, dragId, setDragId, setDropped } = useDrag({
    onDrop: (dragId, dropTargetId, index) =>
      moveQuestion(dragId, dropTargetId, index),
  });

  const [setSectionCollapse, isSectionCollapsed] = useRecord();
  function handleSectionDelete(s: number) {
    const section = survey.sections[s];
    if (
      section.title.length ||
      section.objectives.length ||
      section.questions.length
    ) {
      const confirm = window.confirm(
        "Are you sure you want to delete this section?"
      );
      if (!confirm) return;
    }
    trackEvent("section_delete", {
      survey_id: survey.id,
    });
    removeSection(s);
  }

  function handleRemoveQuestion(sectionId: string, id: string) {
    const section = survey.sections.find((s) => s.id === sectionId);
    if (!section)
      throw new Error("Could not find section with id " + sectionId);
    const question = section.questions.find((q) => q.id === id);
    if (!question) throw new Error("Could not find question with id " + id);
    removeQuestion(sectionId, id);
  }
  if (generating) {
    return (
      <div className="px-8 py-12 mx-auto">
        <CenteredSpinner />
      </div>
    );
  }
  return (
    <div
      className="px-8 mx-auto"
      data-testid="survey-editor"
      style={{ maxWidth: 800, height: collapse ? 80 : undefined }}
    >
      <div ref={sectionRef}>
        {survey.sections.map((section: any, s: number) => (
          <Section
            i={s}
            key={section.id}
            section={section}
            onChange={(section: any) => setSection(s, section)}
            onDeleteClick={() => handleSectionDelete(s)}
            onAddClick={() => addSection(s + 1)}
            onAddQuestionClick={() =>
              addQuestion(section.id, section.questions.length)
            }
            setCollapsed={(value) => setSectionCollapse(section.id, value)}
            isCollapsed={isSectionCollapsed(section.id)}
            handleSubmit={() => addQuestion(section.id, 0)}
            isScreener={s === 0}
          >
            <QuestionSet
              sectionNumber={s + 1}
              questions={section.questions}
              startNumber={sectionStartingNumbers[s]}
              onQuestionChange={(
                id: string,
                text: string,
                options?: {
                  accepted?: boolean;
                  previousText?: string;
                }
              ) => setQuestion(section.id, id, text, options)}
              onDelete={(questionId) =>
                handleRemoveQuestion(section.id, questionId)
              }
              onAdd={(i: number, content?: any) => {
                const questionId = addQuestion(section.id, i, content);
                moveCursorTo(questionId, 0);
              }}
              dropIndex={
                dropTarget && section.id === dropTarget.id
                  ? dropTarget.index
                  : null
              }
              setDropIndex={(index: number | null) =>
                setDropTarget(index === null ? null : { id: section.id, index })
              }
              onDrop={() => setDropped(true)}
              dragId={dragId}
              setDragId={setDragId}
              registerCursorUpdate={registerCursorUpdate}
              requestedCursorPosition={requestedCursorPosition}
              onQuestionLengthChange={(questionId: string, length: number) =>
                registerInputLength(questionId, length)
              }
              moveCursorTo={moveCursorTo}
            />
          </Section>
        ))}
      </div>
    </div>
  );
}
