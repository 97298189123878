import { useState } from "react";
import { saveFileFromBlob } from "../../../../utils/download";
import { download } from "../../surveyAPI";
import { handleError } from "../../../errors/errorUtils";
import {
  autoUpdate,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
} from "@floating-ui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { CenteredSpinner } from "../../../../components/Spinner";
import { useAppSelector } from "../../../../app/hooks";

export default function ExportButton({ surveyId }: { surveyId: string }) {
  const user = useAppSelector((state) => state.auth.user);
  const [isOpen, setIsOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      flip(),
      shift({ padding: 8 }),
      offset({ mainAxis: -66, alignmentAxis: 32 }),
    ],
    placement: "left-start",
    strategy: "fixed",
    whileElementsMounted: autoUpdate,
  });
  const click = useClick(context);
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
  ]);

  async function handleDownloadClick(type: "docx" | "txt" | "json") {
    try {
      if (submitting) return;
      setSubmitting(true);
      setIsOpen(false);
      const { data, headers } = await download(surveyId, type);
      saveFileFromBlob(
        data,
        headers["content-disposition"].replaceAll("attachment; filename=", "")
      );
    } catch (err) {
      handleError(err);
    } finally {
      setSubmitting(false);
    }
  }
  const width = 100;
  return (
    <>
      {submitting ? (
        <div style={{ width, paddingTop: 5 }}>
          <CenteredSpinner size="sm" />
        </div>
      ) : (
        <button
          className="text-sm hover:bg-gray-100 rounded py-2 px-4"
          style={{ width }}
          ref={refs.setReference}
          {...getReferenceProps()}
        >
          Export
          <ChevronDownIcon className="w-4 h-4 inline-block ml-1 relative -top-0.5" />
        </button>
      )}
      {isOpen && !submitting && (
        <div
          ref={refs.setFloating}
          {...getFloatingProps()}
          style={{ ...floatingStyles, width }}
          className="bg-white rounded border border-gray-200 shadow-sm z-50"
        >
          <div
            className="px-4 py-1.5 hover:bg-gray-100 cursor-pointer"
            onClick={() => handleDownloadClick("docx")}
          >
            .docx
          </div>
          <div
            className="px-4 py-1.5 hover:bg-gray-100 cursor-pointer"
            onClick={() => handleDownloadClick("txt")}
          >
            .txt
          </div>
          {user && isInternalEmail(user.email) ? (
            <div
              className="px-4 py-1.5 hover:bg-gray-100 cursor-pointer"
              onClick={() => handleDownloadClick("json")}
            >
              .json
            </div>
          ) : null}
        </div>
      )}
    </>
  );
}

function isInternalEmail(email: string) {
  return (
    email.includes("@incquery.com") ||
    email.includes("@inc-query.com") ||
    email.includes("@plucky.ai")
  );
}
