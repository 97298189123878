import { Dispatch, SetStateAction, useState } from "react";
import { SurveyData } from "../survey.types";
import EditableDiv from "./components/EditableDiv";
import styles from "./PlanEditor.module.css";

export default function PlanEditor({
  survey,
  setSurvey,
}: {
  survey: SurveyData;
  setSurvey: Dispatch<SetStateAction<SurveyData>>;
}) {
  return (
    <EditableDiv
      value={survey.plan}
      ariaLabel="Plan"
      onChange={(plan) =>
        setSurvey((survey) => ({
          ...survey,
          plan,
        }))
      }
      placeholder="Type your outline here..."
      className={`${styles["plan-input"]} block w-full rounded-md border-0 py-3 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6`}
    />
  );
}
