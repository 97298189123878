const entities = {
  amp: "&",
  apos: "'",
  lt: "<",
  gt: ">",
  quot: '"',
  nbsp: "\xa0",
} as { [key: string]: string };
const entityPattern = /&([a-z]+);/gi;

export function fromHTML(html: string) {
  let text = html.replace(/<br\s*\/?>/gi, "\n"); // Replace all <br>, <br/>, and <br /> with newline

  // Replace <p> tags when preceded by a newline and </p> tags when followed by a newline
  text = text.replace(/(?<=\n)<p>|<\/p>(?=\n)/gi, "");

  // Replace remaining <p> and </p> tags with newlines
  text = text.replace(/<p>/gi, "\n").replace(/<\/p>/gi, "\n");

  return text.replace(entityPattern, function (match, entity) {
    entity = entity.toLowerCase();
    if (entities.hasOwnProperty(entity)) {
      return entities[entity];
    }
    // return original string if there is no matching entity (no replace)
    return match;
  });
}

export function toHTML(text: string) {
  return text
    .replaceAll("&", "&amp;")
    .replaceAll("<", "&lt;")
    .replaceAll(">", "&gt;")
    .replaceAll('"', "&quot;")
    .replaceAll("\xa0", "&nbsp;")
    .split("\n")
    .map((line) => `<p>${line}</p>`)
    .join("\n");
}
