import axios from "axios";

export async function submitLogin(
  email: string,
  password: string
): Promise<any> {
  const { data } = await axios.post(`/auth/login`, { email, password });
  const { data: user } = await axios.get("/api/users/me");
  return {
    user,
  };
}

export async function submitLogout(): Promise<any> {
  const { data } = await axios.post(`/auth/logout`);
  return data;
}

export async function patchMyProfile(body: { name: string }): Promise<any> {
  const { data } = await axios.patch(`/api/users/me`, body);
  return data;
}
