import { Disclosure } from "@headlessui/react";
import Wordmark from "./Wordmark";
import { Link } from "react-router-dom";
import { UserIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import { useState } from "react";
import { CenteredSpinner } from "../../components/Spinner";
import { handleError } from "../errors/errorUtils";

const links = [
  {
    name: "Home",
    to: "https://app.incquery.com",
  },
  {
    name: "Knowledge Base",
    to: "https://www.incquery.com/knowledge",
  },
  {
    name: "Data Analysis",
    to: "https://www.incquery.com/analytics/overview",
  },
];

export default function TopNav({ user }: { user: any }) {
  const [loading, setLoading] = useState(false);
  const isInternal =
    user.email &&
    (user.email.includes("@incquery.com") ||
      user.email.includes("@plucky.ai") ||
      user.email.includes("@inc-query.com"));

  async function uploadFile(file: File) {
    if (loading) return;
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);

    try {
      const { data } = await axios.post("/api/exports/word-to-json", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        timeout: 3 * 60 * 1000, // 3 minutes timeout
      });
      const blob = new Blob([JSON.stringify(data, null, 2)], {
        type: "application/json",
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `${file.name.replace(/\.docx$/, "")}-${Date.now()}.json`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error uploading file:", error);
      handleError(error);
    } finally {
      setLoading(false);
    }
  }
  return (
    <Disclosure
      as="nav"
      className="text-white shadow-md"
      style={{ backgroundColor: "#09415A" }}
    >
      {({ open }) => (
        <>
          <div className="mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex h-14 justify-between">
              <div className="flex">
                <div className="flex flex-shrink-0 items-center mt-1">
                  <a
                    href="https://app.incquery.com"
                    style={{ transform: `scale(45%)` }}
                  >
                    <Wordmark />
                  </a>
                </div>
                <div className="sm:ml-6 sm:flex sm:space-x-1 flex-1">
                  {links.map((link) => (
                    <a
                      href={link.to}
                      key={link.name}
                      style={{
                        fontFamily: "Roboto, Helvetica, Arial, sans",
                      }}
                      className="uppercase inline-flex items-center border-b-2 border-transparent px-6 pt-1 text-sm font-medium hover:bg-nav-hover"
                    >
                      {link.name}
                    </a>
                  ))}
                  <Link
                    to={"/drafter"}
                    style={{
                      fontFamily: "Roboto, Helvetica, Arial, sans",
                    }}
                    className="uppercase inline-flex items-center border-b-2 border-transparent px-6 pt-1 text-sm font-medium hover:bg-nav-hover border-white"
                  >
                    AI Drafter
                  </Link>
                </div>
              </div>
              <div className="flex items-center">
                {loading ? (
                  <div className="w-24">
                    <CenteredSpinner size="sm" />
                  </div>
                ) : isInternal ? (
                  <label
                    className="border mr-8 text-sm py-1.5 px-4 rounded-lg hover:text-sky-100 hover:border-sky-100 cursor-pointer"
                    style={{ backgroundColor: "#09415A" }}
                  >
                    Import
                    <input
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        const file = e.target.files?.[0];
                        if (file) {
                          // Handle file upload
                          uploadFile(file);
                        }
                      }}
                    />
                  </label>
                ) : null}
                <a
                  className="bg-gray-200 rounded-full w-8 h-8 hover:bg-gray-100 hover:cursor"
                  href="http://app.incquery.com"
                >
                  <UserIcon
                    className="w-8 h-8 text-gray-500 relative"
                    style={{ top: 4 }}
                  />
                </a>
              </div>
            </div>
          </div>
        </>
      )}
    </Disclosure>
  );
}
