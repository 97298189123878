import { AxiosError } from "axios";

export function extractErrorMessage(err: AxiosError | Error) {
  if (err instanceof AxiosError && err.response?.data?.message) {
    return err.response?.data?.message;
  }
  return "An unexpected error occurred.";
}

export async function throwIfError(actionPromise: Promise<any>) {
  const action = await actionPromise;
  if (action.error) {
    throw action.error;
  }
  return action;
}
