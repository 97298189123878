import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import auth from "../features/auth/authSlice";
import surveys from "../features/survey/surveySlice";
import completionSessions from "../features/completionSession/completionSessionSlice";

export const storeConfig = {
  reducer: {
    auth,
    surveys,
    completionSessions,
  },
};
export const store = configureStore(storeConfig);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppStore = typeof store;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
