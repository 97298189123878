import axios from "axios";
import { pipeStream } from "../../utils/stream";

export async function createSurveyCompletion(surveyId: string) {
  const { data } = await axios.post(
    `/api/completions/survey`,
    {
      surveyId,
    },
    {
      timeout: 120000,
    }
  );
  return data;
}

export async function createPlanCompletion(
  body: { description: string; surveyId: string },
  cb: (text: string | null, done?: boolean) => void
) {
  return pipeStream(`/drafter/api/completions/plan`, body, cb);
}

export async function createTitleCompletion(
  description: string,
  cb: (text: string | null, done?: boolean) => void
) {
  return pipeStream(`/drafter/api/completions/title`, { description }, cb);
}
export async function createQuestionCompletion(body: {
  questionId: string;
  change: string;
}) {
  const { data } = await axios.post(`/api/completions/question`, body, {
    timeout: 900000,
  });
  return data.content;
}
