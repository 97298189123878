import { useEffect, useRef, useState } from "react";
import { useSurvey, useSurveySetters } from "./hooks/useSurvey";
import Wizard from "./components/wizard/Wizard";
import { SectionData, SurveyData } from "../survey.types";
import { SurveyWorkspaceHeader } from "./components/SurveyWorkspaceHeader";
import SurveyEditor from "./SurveyEditor";
import { handleError } from "../../errors/errorUtils";
import { autoScrollForElements } from "@atlaskit/pragmatic-drag-and-drop-auto-scroll/element";
import invariant from "tiny-invariant";
import { useAppSelector } from "../../../app/hooks";
import { SurveyContext } from "./surveyContext";
import { createSurveyCompletion } from "../../completion/completionAPI";
import { toHTML } from "../../../../../shared/utils/html";

function selectActiveSectionId(sections: SectionData[]): string | null {
  // Active section is the last section prior to an empty one
  for (let i = 0; i < sections.length; i++) {
    const section = sections[i];
    if (section.questions.length === 0) {
      if (i === 0) return null;
      return sections[i - 1].id;
    }
  }

  return null;
}

function selectNextEmptySectionId(
  sections: SectionData[],
  sectionId: string | null
) {
  const surveyIsEmpty = sections.every(
    (section, s) =>
      section.questions.length === 0 ||
      (s === 0 && section.questions.length === 1)
  );
  if (surveyIsEmpty) return sections[0].id;
  if (sectionId === null) return null;
  const index = sections.findIndex((section) => sectionId === section.id);
  const emptySections = sections
    .slice(index + 1)
    .filter((section) => section.questions.length === 0);
  return emptySections[0]?.id || null;
}

export default function SurveyWorkspace({
  survey: savedSurvey,
}: {
  survey: SurveyData;
}) {
  const ref = useRef<HTMLDivElement | null>(null);
  const user = useAppSelector((state) => state.auth.user);
  const { survey, setSurvey, saveSurvey } = useSurvey(savedSurvey);
  const { questionCount } = useSurveySetters(survey, setSurvey);
  const [showEditor, setShowEditor] = useState(questionCount > 1);
  const [scrollUp, setScrollUp] = useState(false);
  const [generating, setGenerating] = useState(false);
  const activeSectionId = selectActiveSectionId(survey.sections);
  const nextSectionId = selectNextEmptySectionId(
    survey.sections,
    activeSectionId
  );

  async function handleGenerateSurvey() {
    let originalShowEditorState = showEditor;
    setShowEditor(true);

    const nextSection = survey.sections.find(
      (section) => section.id === nextSectionId
    );
    if (!nextSectionId || !nextSection) return;

    try {
      setGenerating(true);
      await saveSurvey(survey);
      const { sections } = await createSurveyCompletion(survey.id);
      setSurvey((survey) => ({
        ...survey,
        sections: sections.map((section: any) => ({
          ...section,
          questions: section.questions.map((question: any) => ({
            ...question,
            text: toHTML(question.text),
          })),
        })),
      }));
    } catch (err) {
      handleError(err);
      if (!originalShowEditorState) {
        setShowEditor(false);
      }
    } finally {
      setGenerating(false);
      setScrollUp(true);
    }
  }

  useEffect(() => {
    if (scrollUp && ref.current) {
      ref.current.scrollTo(0, 0);
      setScrollUp(false);
    }
  }, [scrollUp, ref.current]);

  const headerHeight = 42;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [survey.id]);

  useEffect(() => {
    if (!showEditor) return;
    const element = ref.current;
    invariant(element);
    return autoScrollForElements({
      element,
      getConfiguration: () => ({
        maxScrollSpeed: "fast",
      }),
    });
  }, [showEditor]);

  return (
    <SurveyContext.Provider value={{ survey, setSurvey }}>
      <div className="overflow-visible relative flex-1 flex-col flex h-screen">
        <SurveyWorkspaceHeader
          surveyId={survey.id}
          title={survey.title}
          setTitle={(title: string) =>
            setSurvey((survey) => ({
              ...survey,
              title,
            }))
          }
          height={headerHeight}
          isDebugAvailable={user?.role === "admin"}
          showSubmit={showEditor}
          setSurvey={setSurvey}
          isSubmitted={!!survey.submittedAt}
        />
        <div
          className={`flex flex-row flex-1 ${
            showEditor ? "" : "overflow-y-scroll"
          }`}
          style={{ maxHeight: `calc(100vh - ${headerHeight}px)` }}
        >
          <div
            style={
              showEditor
                ? {
                    width: "40%",
                    minWidth: 700,
                    maxWidth: 720,
                  }
                : {
                    width: 1000,
                    maxWidth: 1000,
                  }
            }
            className={`pb-12 mx-auto ${
              showEditor ? "overflow-y-scroll" : "pt-8"
            }`}
          >
            <Wizard
              survey={survey}
              setSurvey={setSurvey}
              onGenerateSurvey={handleGenerateSurvey}
              generatingSurvey={generating}
            />
            {showEditor ? null : (
              <button
                className="fixed bottom-4 right-8 h-20 w-20 text-transparent"
                onClick={() => setShowEditor(true)}
                aria-label="Skip Wizard"
              ></button>
            )}
          </div>

          {showEditor && (
            <div
              ref={ref}
              className="flex-1 overflow-y-scroll mx-auto px-8"
              style={{
                maxHeight: `calc(100vh - ${headerHeight}px)`,
                paddingTop: 80,
                paddingBottom: 80,
              }}
            >
              <SurveyEditor
                survey={survey}
                setSurvey={setSurvey}
                generating={generating}
              />
            </div>
          )}
        </div>
      </div>
    </SurveyContext.Provider>
  );
}
