import { useState } from "react";

export function useRecord(): [
  (key: string, value: any) => void,
  (key: string) => any
] {
  const [data, setData] = useState<{ [key: string]: any }>({});
  function set(key: string, value: any) {
    setData((prev) => ({ ...prev, [key]: value }));
  }
  function get(key: string) {
    return data[key];
  }
  return [set, get];
}
