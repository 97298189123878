import { CheckIcon } from "@heroicons/react/20/solid";
import ExportButton from "../features/survey/editor/components/ExportButton";
import { Link, useParams } from "react-router-dom";
import { Redirect } from "../features/nav/Redirect";

export default function SurveySubmittedView() {
  const { surveyId } = useParams();
  if (!surveyId) return <Redirect to="/drafter/surveys" />;
  return (
    <div className="flex-1 max-h-screen  flex flex-col justify-center items-center">
      <div className="pb-60 max-w-md">
        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
          <CheckIcon aria-hidden="true" className="h-6 w-6 text-green-600" />
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <h3 className="text-base font-semibold text-gray-900">
            Survey submitted!
          </h3>
          <div className="mt-2">
            <p className="text-sm text-gray-500 text-left">
              The IncQuery team will reach out to you soon. If you need to
              export a copy of your survey, you can do so below.
            </p>
            <div className="pt-6 pb-8">
              <ExportButton surveyId={surveyId} />
            </div>
            <div>
              <Link
                to="/drafter/surveys"
                className="rounded bg-gray-100 px-6 py-3 text-sm hover:bg-gray-200"
              >
                Back to drafts
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
