import { createContext, useContext } from "react";
import { SurveyData } from "../survey.types";
// Create a context
export const SurveyContext = createContext<{
  survey: SurveyData;
  setSurvey: (survey: SurveyData) => void;
}>({
  survey: {
    id: "",
    title: "",
    sections: [],
    objectives: "",
    plan: "",
    audience: "",
    respondentType: "",
    example: "",
  },
  setSurvey: (survey: SurveyData) => {},
});

// Custom hook for consuming the context
export const useSurveyContext = () => useContext(SurveyContext);
