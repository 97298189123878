import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  getAllCompletionSessions,
  selectManyCompletionSessionsBySurveyId,
} from "./completionSessionSlice";
import { formatDistanceToNow, isBefore } from "date-fns";
import { XMarkIcon } from "@heroicons/react/24/outline";

function CompletionSessionList({
  sessions,
  onClick,
}: {
  sessions: any[];
  onClick: (completionSessionId: string) => void;
}) {
  return (
    <div className="border-r h-full w-full overflow-y-scroll max-h-full">
      {sessions.map((session) => (
        <button
          key={session.id}
          onClick={() => onClick(session.id)}
          className="flex flex-row px-4 py-2 hover:bg-gray-100 w-full border-b"
        >
          <div>{session.type}</div>
          <div className="flex-1 text-xs text-gray-500 text-right">
            {formatDistanceToNow(session.createdAt, { addSuffix: true })}
          </div>
        </button>
      ))}
    </div>
  );
}

function CompletionSessionLog({ session }: { session: any }) {
  return (
    <div className="whitespace-pre-wrap px-6 pt-4 pb-32 overflow-y-scroll max-h-full space-y-6">
      <div>
        <label className="font-bold">Input</label>
        <div>{session.input || "None"}</div>
      </div>
      <div>
        <label className="font-bold">Output</label>
        <div>{session.output || "None"}</div>
      </div>
      <div>
        <label className="font-bold">Log</label>
        <div>{session.log || "None"}</div>
      </div>
    </div>
  );
}

export function DebugPanel({
  show,
  setShow,
  surveyId,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  surveyId: string;
}) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (show) {
      dispatch(getAllCompletionSessions(surveyId));
    }
  }, [show]);
  const sessions = useAppSelector((state) =>
    selectManyCompletionSessionsBySurveyId(state, surveyId)
  ).sort((a: any, b: any) => (isBefore(a.createdAt, b.createdAt) ? 1 : -1));
  const [activeCompletionSessionId, setActiveCompletionSessionId] = useState<
    string | null
  >(null);
  const activeCompletionSession =
    sessions.find((session: any) => session.id === activeCompletionSessionId) ||
    null;
  if (!show) return null;
  return (
    <Dialog className="relative z-10" open={show} onClose={setShow}>
      <DialogBackdrop
        transition
        className="fixed inset-0 top-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative w-full transform overflow-hidden rounded-lg bg-white pb-4 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:mx-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            style={{ height: `calc(100vh - 4rem)` }}
          >
            <div className="flex flex-row items-center h-12 border-b">
              <div className="px-4 text-sm font-bold flex-1">Debug</div>
              <div className="px-4">
                <button
                  onClick={() => setShow(false)}
                  className="hover:bg-gray-200 p-1.5 rounded-lg"
                >
                  <XMarkIcon className="w-4 h-4" />
                </button>
              </div>
            </div>
            <div className="flex flex-row h-full">
              <div style={{ width: 300, minWidth: 300 }} className="h-full">
                <CompletionSessionList
                  sessions={sessions}
                  onClick={(completionSessionId) =>
                    setActiveCompletionSessionId(completionSessionId)
                  }
                />
              </div>
              <div className="flex-1">
                {activeCompletionSession ? (
                  <CompletionSessionLog session={activeCompletionSession} />
                ) : null}
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
