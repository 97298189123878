const useHttps = !!import.meta.env.VITE_USE_HTTPS;

const config = {
  env: import.meta.env.VITE_ENV,
  production: import.meta.env.VITE_ENV === "production",
  test: import.meta.env.VITE_ENV === "test",
  posthog: {
    clientId: import.meta.env.VITE_POSTHOG_CLIENT_ID,
    recordInputValues: true,
  },
  sentry: {
    dsn: import.meta.env.VITE_SENTRY_FRONTEND_DSN,
    env: import.meta.env.VITE_SENTRY_FRONTEND_ENV || "development",
  },
  plucky: {
    url: import.meta.env.VITE_PLUCKY_API_URL || "https://api.plucky.ai",
    publicApiKey: import.meta.env.VITE_PLUCKY_PUBLIC_API_KEY,
  },
  useHttps,
};

export default config;
