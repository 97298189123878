export interface NormalizedState<T> {
  byId: T;
  allIds: Array<string>;
}

export interface ManyItemPayload {
  results: Array<any>;
}

export function initNormalizedState(): NormalizedState<any> {
  return {
    byId: {},
    allIds: [],
  };
}

export function toNormalizedState(items: Array<any>) {
  const state = initNormalizedState();
  addNormalizedItems(state, items);
  return state;
}
interface AddNormalizedItemsOptions {
  key?: string;
  merge?: boolean;
}

export function addNormalizedItems(
  state: any,
  items: Array<any>,
  options?: AddNormalizedItemsOptions
) {
  options = options || {};
  const idKey = options?.key || "id";

  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    const id = item[idKey];
    if (options.merge) {
      state.byId[id] = Object.assign(state.byId[id] || {}, item);
    } else {
      state.byId[id] = item;
    }
    if (state.allIds.indexOf(id) == -1) state.allIds.push(id);
  }
  return state;
}

export function removeNormalizedItem(state: any, id: string) {
  delete state.byId[id];
  state.allIds = state.allIds.filter((existingId: string) => existingId !== id);
}

export function toArray(state: any) {
  return state.allIds.map((id: string) => state.byId[id]);
}
