import styles from "./Spinner.module.css";
interface SpinnerProps {
  size?: "sm" | "md";
  color?: "dark" | "light";
}
export default function Spinner({ size, color }: SpinnerProps) {
  const loaderSize = size === "sm" ? styles["loader--sm"] : "";
  const loaderColor = color === "light" ? styles["loader--white"] : "";
  return (
    <span className={`${styles.loader} ${loaderSize} ${loaderColor}`}></span>
  );
}

export function CenteredSpinner({ size }: { size?: "sm" | "md" }) {
  return (
    <div className="flex-1 flex flex-row justify-center items-center">
      <div>
        <Spinner size={size} />
      </div>
    </div>
  );
}
