import axios from "axios";
import { identifyUser } from "../../utils/tracking";

export async function fetchMyProfile(): Promise<any> {
  const { data } = await axios.get("/api/users/me");
  const { id, incQueryId, email, name } = data;
  identifyUser(id, { email, name, incQueryId });
  return data;
}

export async function submitLogin(
  email: string,
  password: string
): Promise<any> {
  const { data } = await axios.post(`/auth/login`, { email, password });
  return data;
}

export async function submitLogout(): Promise<any> {
  const { data } = await axios.post(`/auth/logout`);
  return data;
}

export async function requestMagicLink(email: string): Promise<any> {
  const { data } = await axios.post(`/auth/magic-link`, { email });
  return data;
}

export async function getHpAuthToken() {
  const { data } = await axios.get("/auth/hp-token");
  return data;
}
