export default function Wordmark() {
  return (
    <svg
      viewBox="435 485 1050 225"
      id="logo"
      role="img"
      aria-label="IncQuery Wordmark"
      height="3em"
      width="14em"
    >
      <title>Logo for IncQuery</title>
      <g fill="#FFFFFF">
        {" "}
        {/* Apply the fill color to the group */}
        <path d="M443.38 653.34V490.7h39.96v162.64h-39.96zM508.44 653.34V530.43h37.64v8.36c9.29-7.12 20.29-10.69 32.99-10.69 9.6 0 18.08 2.09 25.44 6.27 7.35 4.18 13.13 9.99 17.31 17.43 4.18 7.44 6.27 16.03 6.27 25.79v75.75h-37.64v-70.63c0-6.81-2.01-12.23-6.04-16.26-4.03-4.03-9.45-6.04-16.26-6.04-4.8 0-9.03.85-12.66 2.56a27.69 27.69 0 0 0-9.41 7.2v83.18h-37.64z"></path>
        <path d="M709.88 655.66c-12.24 0-23.27-2.79-33.11-8.37-9.84-5.58-17.62-13.2-23.35-22.89-5.73-9.68-8.6-20.48-8.6-32.41s2.86-22.92 8.6-32.53c5.73-9.6 13.51-17.23 23.35-22.89 9.84-5.65 20.87-8.48 33.11-8.48 10.07 0 19.67 1.98 28.81 5.93 9.14 3.95 16.88 9.57 23.23 16.85l-23 23.93c-4.34-4.96-8.75-8.56-13.24-10.8s-9.45-3.37-14.87-3.37-10.3 1.36-14.64 4.07-7.75 6.43-10.22 11.15c-2.48 4.73-3.72 10.11-3.72 16.15s1.28 11.19 3.83 15.92c2.56 4.73 6.08 8.44 10.57 11.15 4.49 2.71 9.53 4.07 15.1 4.07 5.11 0 9.83-1.08 14.17-3.25 4.33-2.17 8.52-5.5 12.55-9.99l22.31 23.23c-6.2 7.13-13.79 12.66-22.77 16.61-8.99 3.95-18.36 5.93-28.11 5.93z"></path>
        <path d="m903.42 662.86-10.69-13.48c-5.27 2.02-10.73 3.6-16.38 4.76-5.66 1.16-11.58 1.74-17.77 1.74-12.24 0-23.62-2.13-34.16-6.39-10.54-4.26-19.75-10.22-27.65-17.89-7.9-7.67-14.06-16.57-18.47-26.72-4.41-10.14-6.62-21.1-6.62-32.88s2.21-22.73 6.62-32.88c4.41-10.14 10.57-19.05 18.47-26.72 7.9-7.67 17.11-13.63 27.65-17.89 10.53-4.26 21.92-6.39 34.16-6.39s23.62 2.13 34.16 6.39c10.53 4.26 19.75 10.22 27.65 17.89 7.9 7.67 14.06 16.58 18.47 26.72 4.41 10.15 6.62 21.11 6.62 32.88s-2.13 21.96-6.39 31.95c-4.26 9.99-10.11 18.78-17.54 26.37l15.8 19.98-33.92 12.55zm-44.84-43.68c1.7 0 3.37-.08 5-.23 1.63-.15 3.21-.39 4.76-.7L853 598.73l33.92-12.55 9.76 12.55c2.48-3.72 4.37-7.86 5.69-12.43 1.31-4.57 1.98-9.33 1.98-14.29 0-6.81-1.16-13.09-3.49-18.82-2.32-5.73-5.54-10.69-9.64-14.87-4.11-4.18-8.95-7.47-14.52-9.87-5.58-2.4-11.62-3.6-18.12-3.6s-12.55 1.2-18.12 3.6c-5.58 2.4-10.42 5.69-14.52 9.87-4.11 4.18-7.32 9.14-9.64 14.87-2.32 5.73-3.49 12.01-3.49 18.82s1.16 12.9 3.49 18.7 5.54 10.8 9.64 14.99c4.1 4.18 8.95 7.48 14.52 9.88 5.58 2.4 11.62 3.6 18.12 3.6z"></path>
        <path d="M1012.86 655.66c-9.61 0-18.09-2.09-25.44-6.27-7.36-4.18-13.13-10.03-17.31-17.54-4.18-7.51-6.27-16.07-6.27-25.67v-75.75h37.64v70.63c0 6.66 2.05 12.05 6.16 16.15 4.1 4.11 9.49 6.16 16.15 6.16 4.8 0 9.06-.85 12.78-2.56 3.72-1.7 6.81-4.1 9.29-7.2v-83.18h37.64v122.91h-37.64v-8.36c-9.29 7.13-20.29 10.69-32.99 10.69z"></path>
        <path d="M1168.07 655.66c-12.55 0-23.89-2.83-34.04-8.48-10.15-5.65-18.16-13.28-24.05-22.89-5.89-9.6-8.83-20.37-8.83-32.3s2.82-22.69 8.48-32.3c5.65-9.6 13.36-17.23 23.12-22.89 9.76-5.65 20.6-8.48 32.53-8.48s22.77 2.9 32.06 8.71c9.29 5.81 16.61 13.75 21.96 23.82 5.34 10.07 8.02 21.61 8.02 34.62v9.29h-87.6c1.7 3.87 3.99 7.28 6.85 10.22 2.86 2.94 6.31 5.23 10.34 6.85 4.03 1.63 8.36 2.44 13.01 2.44 5.11 0 9.76-.81 13.94-2.44s7.74-3.91 10.69-6.85l24.86 22.07c-7.75 6.51-15.68 11.23-23.82 14.17-8.13 2.94-17.31 4.42-27.53 4.42zm-29.04-77.14h51.35c-1.24-4.03-3.14-7.47-5.69-10.34-2.56-2.86-5.54-5.11-8.95-6.74-3.41-1.63-7.13-2.44-11.15-2.44s-7.98.78-11.39 2.32a24.43 24.43 0 0 0-8.71 6.62c-2.4 2.87-4.22 6.39-5.46 10.57z"></path>
        <path d="M1244.97 653.34V530.43h37.64v12.31c4.03-4.96 8.75-8.71 14.17-11.27 5.42-2.56 11.46-3.83 18.12-3.83 6.66.16 11.39 1.16 14.17 3.02v32.76c-2.48-1.24-5.27-2.17-8.36-2.79-3.1-.62-6.27-.93-9.53-.93-5.89 0-11.35 1.36-16.38 4.07-5.03 2.71-9.1 6.55-12.2 11.5v78.07h-37.64z"></path>
        <path d="M1362.3 703.52c-2.94 0-5.81-.12-8.6-.35-2.79-.23-5.11-.58-6.97-1.05v-32.06c3.1.62 6.97.93 11.62.93 12.08 0 20.21-5.27 24.4-15.8v-.23l-47.17-124.54h41.59l27.18 77.37 31.6-77.37h40.66l-55.07 129.19c-4.49 10.69-9.45 19.25-14.87 25.67-5.42 6.43-11.7 11.07-18.82 13.94-7.13 2.86-15.65 4.3-25.56 4.3z"></path>
      </g>
    </svg>
  );
}
