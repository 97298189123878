import React from "react";

export default function SectionDivider({
  onAddSectionClick,
  onAddQuestionClick,
}: {
  onAddSectionClick: () => void;
  onAddQuestionClick: () => void;
}) {
  function handleAddSectionClick(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation();
    e.preventDefault();
    onAddSectionClick();
  }
  function handleAddQuestionClick(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation();
    e.preventDefault();
    onAddQuestionClick();
  }
  return (
    <div
      className={`opacity-0 hover:opacity-100 relative transition-opacity duration-100 py-2 -mt-2 space-x-4`}
      aria-label="Section Divider"
    >
      <button
        onClick={handleAddSectionClick}
        type="button"
        className=" text-gray-400 hover:text-gray-800"
        aria-label="Add Section"
      >
        <span>+ Section</span>
      </button>
      <button
        onClick={handleAddQuestionClick}
        type="button"
        className=" text-gray-400 hover:text-gray-800"
        aria-label="Add question to end of section"
      >
        <span>+ Question</span>
      </button>
    </div>
  );
}
