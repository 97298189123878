import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
  addNormalizedItems,
  initNormalizedState,
  NormalizedState,
  removeNormalizedItem,
  toArray,
} from "../../utils/normalizedState";
import { archive, fetchAll, fetchOne, update } from "./surveyAPI";

const initialState: NormalizedState<any> = initNormalizedState();
export const getAllWorkspaces = createAsyncThunk("surveys/getAll", async () => {
  const response = await fetchAll({});
  return response.results;
});

export const getSurvey = createAsyncThunk(
  "surveys/getOne",
  async (id: string) => {
    const response = await fetchOne(id);
    return response;
  }
);
export const getAllSurveys = createAsyncThunk("surveys/getAll", async () => {
  const response = await fetchAll({});
  return response;
});

export const updateSurvey = createAsyncThunk(
  "surveys/update",
  async ({ id, ...body }: any) => {
    try {
      const response = await update(id, body);
      return response;
    } catch (err) {
      throw err;
    }
  }
);
export const archiveSurvey = createAsyncThunk(
  "surveys/archive",
  async (id: string) => {
    try {
      await archive(id);
      return {
        id,
      };
    } catch (err) {
      throw err;
    }
  }
);

export const surveySlice = createSlice({
  name: "surveys",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(
        getSurvey.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          addNormalizedItems(state, [action.payload]);
        }
      )
      .addCase(
        getAllSurveys.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          addNormalizedItems(state, action.payload.results);
        }
      )
      .addCase(
        updateSurvey.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          addNormalizedItems(state, [action.payload]);
        }
      )
      .addCase(
        archiveSurvey.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          removeNormalizedItem(state, action.payload.id);
        }
      );
  },
});

export const selectAllSurveys = createSelector(
  [(state: RootState) => state.surveys],
  (surveys: any) => {
    return toArray(surveys);
  }
);

export const selectSurveyById = createSelector(
  [
    (state: RootState) => state.surveys.byId,
    (state: RootState, surveyId: string) => surveyId,
  ],
  (surveysById: any, surveyId: string) => {
    return surveysById[surveyId] || null;
  }
);

export const selectSurveyByIncQueryId = createSelector(
  [
    (state: RootState) => state.surveys,
    (state: RootState, incQueryId: string) => incQueryId,
  ],
  (normalizedSurveys: any, incQueryId: string) => {
    return toArray(normalizedSurveys).find(
      (survey: any) => survey.incQueryId === incQueryId
    );
  }
);

export default surveySlice.reducer;
