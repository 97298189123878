import { ChevronRightIcon, TrashIcon } from "@heroicons/react/24/outline";
import SectionDivider from "./SectionDivider";
import styles from "./Sections.module.css";
import { motion } from "framer-motion";
import editorStyles from "../SurveyEditor.module.css";
import { useRef } from "react";

export default function Section({
  i,
  section,
  onChange,
  onDeleteClick,
  onAddClick,
  onAddQuestionClick,
  handleSubmit,
  isCollapsed = false,
  children,
  setCollapsed,
  isScreener = false,
  showDivider = true,
}: {
  i: number;
  section: any;
  onChange: (section: any) => void;
  onDeleteClick: () => void;
  onAddClick: () => void;
  onAddQuestionClick: () => void;
  handleSubmit?: () => void;
  onAddQuestion?: (i: number) => void;
  isCollapsed?: boolean;
  setCollapsed?: (value: boolean) => void;
  children?: React.ReactNode;
  isScreener?: boolean;
  showDivider?: boolean;
}) {
  const titleRef = useRef<HTMLInputElement>(null);

  function handleHeadingSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    e.stopPropagation();
    if (!handleSubmit) return;
    if (titleRef.current) titleRef.current.blur();
    handleSubmit();
  }
  return (
    <div
      className={`${styles["survey-section"]}`}
      aria-label="Survey Section"
      data-testid={`section-${i}`}
    >
      <div className={`align-top relative`}>
        <div
          className={`${styles["survey-section__heading"]} relative ${editorStyles["padded-container"]} relative flex flex-row`}
        >
          <div className="-mx-2 flex-1 -mb-3">
            <form
              onSubmit={handleHeadingSubmit}
              className="flex-1 flex flex-row"
            >
              {isScreener ? (
                <div className="text-lg lg:text-xl font-medium h-min-24 py-1 px-2 flex-1">
                  {section.title}
                </div>
              ) : (
                <div className="flex-1 mb-1">
                  <input
                    placeholder="Enter your section title..."
                    ref={titleRef}
                    value={section.title}
                    className="w-full h-full text-lg lg:text-xl font-medium h-min-24 block rounded-md border-0 py-1.5 px-2 text-gray-900 hover:shadow-sm hover:bg-gray-100 placeholder:text-gray-400 focus:ring-0 sm:leading-6"
                    onChange={(e) =>
                      onChange({ ...section, title: e.target.value })
                    }
                    autoFocus
                  />
                </div>
              )}
              <div className={`${editorStyles["row-spacer"]} flex flex-row`}>
                <button
                  type="button"
                  className={`${
                    styles["survey-section__heading__hidden-element"]
                  } p-2 text-gray-400 hover:text-gray-800 rounded ${
                    isScreener ? "invisible" : ""
                  }`}
                  onClick={onDeleteClick}
                  aria-label="Delete Section"
                >
                  <TrashIcon className="w-4 h-4" />
                </button>
                {setCollapsed ? (
                  <motion.button
                    type="button"
                    animate={isCollapsed ? "closed" : "open"}
                    variants={{
                      open: { rotate: 90 },
                      closed: { rotate: 0 },
                    }}
                    className={`${
                      isCollapsed
                        ? "text-gray-600"
                        : `${styles["survey-section__heading__hidden-element"]} text-gray-400`
                    }  hover:text-gray-800 w-6 h-8 flex flex-row justify-center items-center hover:cursor relative`}
                    onClick={() => setCollapsed(!isCollapsed)}
                  >
                    <ChevronRightIcon
                      className="w-4 h-4"
                      style={{ marginLeft: 2 }}
                    />
                  </motion.button>
                ) : null}
              </div>
            </form>
          </div>
        </div>
      </div>
      {isCollapsed || !children ? (
        <div className="h-6" />
      ) : (
        <div className="flex-1">{children}</div>
      )}
      {showDivider ? (
        <SectionDivider
          onAddSectionClick={onAddClick}
          onAddQuestionClick={onAddQuestionClick}
        />
      ) : null}
    </div>
  );
}
