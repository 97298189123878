import { classNames } from "../../utils/styling";

interface ContainerProps {
  children: Array<any> | any;
  className?: string;
}

export default function PaddedContainer({
  children,
  className,
}: ContainerProps) {
  return (
    <div
      className={classNames(
        "mx-auto max-w-4xl px-4 sm:px-6 lg:px-8",
        className
      )}
    >
      {children}
    </div>
  );
}
