import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function handleError(err: any) {
  console.log(err);
  if (err.code === "ValidationError") {
    toast(err.message);
  } else {
    console.error(err);
    toast("An unexpected error has occurred and been reported.");
  }
}
