import { useEffect } from "react";
import "./App.css";
import { Outlet } from "react-router-dom";
import { loadAllProfileData } from "./features/auth/authSlice";
import { useAppDispatch } from "./app/hooks";
import { ToastContainer } from "react-toastify";

function App() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(loadAllProfileData(null));
  }, []);

  return (
    <div id="app">
      <Outlet />
      <ToastContainer theme="colored" />
    </div>
  );
}

export default App;
