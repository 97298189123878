import { useState } from "react";

interface MergeOp {
  itemId: string;
  appendValue: string;
}

export default function useCursor() {
  // When merging inputs, you must first notify the editable div
  // to set cursor before adding the input value
  const [inputLengths, setInputLengths] = useState<{ [key: string]: number }>(
    {}
  );
  function registerInputLength(id: string, value: number) {
    setInputLengths((prev) => ({ ...prev, [id]: value }));
  }

  const [requestedCursorPosition, setRequestedCursorPosition] = useState<{
    id: string;
    pos: number;
  } | null>(null);

  function moveCursorTo(id: string, pos?: number) {
    if (pos !== undefined) {
      setRequestedCursorPosition({
        id,
        pos,
      });
      return;
    }
    const length = inputLengths[id];
    setRequestedCursorPosition({
      id,
      pos: length === undefined ? -1 : length,
    });
  }

  function registerCursorUpdate() {
    setRequestedCursorPosition(null);
  }
  return {
    registerInputLength,
    requestedCursorPosition,
    registerCursorUpdate,
    moveCursorTo,
  };
}
