import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import {
  useFloating,
  autoUpdate,
  useDismiss,
  useInteractions,
} from "@floating-ui/react";

export default function DropdownOptions({
  options,
  buttonClassName,
}: {
  options: { name: string; onClick: () => void }[];
  buttonClassName?: string;
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { refs, floatingStyles, context } = useFloating({
    whileElementsMounted: autoUpdate,
    placement: "left-start",
    open: dropdownOpen,
    onOpenChange: setDropdownOpen,
  });
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

  return (
    <>
      <button
        className={`text-gray-500 hover:text-gray-800 hover:bg-gray-100 rounded-full p-1 ${buttonClassName} mt-1`}
        onClick={() => setDropdownOpen(!dropdownOpen)}
        ref={refs.setReference}
        style={{ opacity: dropdownOpen ? 1 : undefined }}
        {...getReferenceProps()}
      >
        <EllipsisVerticalIcon className="w-6 h-6" />
      </button>
      {dropdownOpen ? (
        <div
          ref={refs.setFloating}
          style={floatingStyles}
          className="bg-white z-10 border rounded text-xs shadow-md"
          {...getFloatingProps()}
        >
          {options.map((option) => (
            <button
              key={option.name}
              className="px-4 py-2.5 block w-full text-left hover:bg-gray-100"
              onClick={() => {
                option.onClick();
                setDropdownOpen(false);
              }}
            >
              {option.name}
            </button>
          ))}
        </div>
      ) : null}
    </>
  );
}
